import moment from 'moment';
import { DEFAULT_OPTION } from './form-helper';
import { REPORT_CONFIG } from './contract-report-types';

export const REPORT_DEFAULTS = {
  contractType: { value: 'SOP' },
  report: { value: '' },
  isActiveContract: false,
};
export const STEP_1_DEFAULTS = {
  contractYears: { value: [] },
  awardedDateFrom: { value: '' },
  awardedDateTo: { value: '' },
};
export const STEP_2_FORM_DEFAULTS_SOL_VENDOR = {
  solicitations: { value: [] },
  vendors: { value: [] },
};
export const STEP_2_FORM_DEFAULTS_CONTRACT = {
  contracts: { value: [] },
  contractPeriods: {
    year1: '',
    period1: '',
    year2: '',
    period2: '',
  },
  fuelTypes: { value: [] },
  sins: { value: [] },
  contractLines: { value: [] },
  contractLineIds: { value: [] },
};
export const STEP_2_FORM_DEFAULTS = {
  ...STEP_2_FORM_DEFAULTS_SOL_VENDOR,
  ...STEP_2_FORM_DEFAULTS_CONTRACT,
};
export const STEP_2_DEFAULTS = {
  ...STEP_2_FORM_DEFAULTS,
  isEmailReport: { value: false },
};

export const getAllContractYears = () => {
  const MAX_NUMBER_OF_YEARS = 5;
  const currentFY = moment().add(92, 'days').year();
  const years = [];
  for (let i = 0; i < MAX_NUMBER_OF_YEARS; i += 1) years.push(currentFY - i);
  return years;
};
export const getContractYearOptions = () => {
  return getAllContractYears().map((fy) => {
    const fyStr = String(fy);
    return { value: fyStr, label: fyStr };
  });
};

export const getSortedOptions = (opts, withDefault = false) => {
  const sorted = opts.sort((a, b) => (a.label < b.label ? -1 : 1));
  return withDefault ? [DEFAULT_OPTION, ...sorted] : sorted;
};

export const getReportOptions = (reportGroups, reportType) => {
  if (reportType)
    return [
      DEFAULT_OPTION,
      ...reportGroups[reportType].map((rpt) => ({
        value: rpt,
        label: REPORT_CONFIG[rpt].label,
      })),
    ];
  return [DEFAULT_OPTION];
};

export const getUniqueSortedOptions = (options, withDefault = false) => {
  const opts = [];
  options.forEach((op) => {
    if (opts.every((o) => o.value !== op.value)) opts.push(op);
  });
  return getSortedOptions(opts, withDefault);
};

const sortOptByLabel = (opt1, opt2) => (opt1.label < opt2.label ? -1 : 1);

export const getSolicitationOptions = (contracts) => {
  const solicitationUpiids = new Set(
    contracts.map((contr) => contr.solicitation.solicitationUpiid),
  );
  return [...solicitationUpiids]
    .map((upiid) => {
      const solIds = new Set(
        contracts
          .filter((contr) => contr.solicitation.solicitationUpiid === upiid)
          .map((contr) => +contr.solicitation.id),
      );
      return { value: JSON.stringify([...solIds]), label: upiid };
    })
    .sort(sortOptByLabel);
};

export const getVendorOptions = (contracts) => {
  const vendorIds = new Set(contracts.map((contr) => contr.vendorId));
  return [...vendorIds]
    .map((vendorId) => {
      const vendorName =
        contracts.find((contr) => contr.vendorId === vendorId).vendor
          ?.vendorName ?? vendorId;
      return { value: vendorId, label: vendorName };
    })
    .sort(sortOptByLabel);
};

export const getContractOptions = (contracts, solIds, vendorIds) => {
  const contractNumbers = new Set(
    contracts
      .filter(
        (contr) =>
          (!solIds.length || solIds.includes(contr.solicitationId)) &&
          (!vendorIds.length || vendorIds.includes(contr.vendorId)),
      )
      .map((contr) => contr.contractNumber),
  );
  return [...contractNumbers]
    .map((contractNumber) => ({ value: contractNumber, label: contractNumber }))
    .sort(sortOptByLabel);
};

export const getFuelTypeOptions = (fuelSinData) => {
  return [...new Set(fuelSinData.map((item) => item.fuelTypeCodeId))]
    .map((fuelTypeCodeId) => {
      const fuelItem = fuelSinData.find(
        (item) => item.fuelTypeCodeId === fuelTypeCodeId,
      );
      return {
        value: String(fuelTypeCodeId),
        label: `${fuelItem.fuelTypeCode} - ${fuelItem.fuelTypeTitle}`,
      };
    })
    .sort(sortOptByLabel);
};

export const getSinOptionsByContractLines = (lineData) => {
  if (!lineData.length) return [];
  const sins = new Set(lineData.map((item) => item.sin));
  return [...sins]
    .map((sin) => ({ value: sin, label: sin }))
    .sort(sortOptByLabel);
};

const getMakeCode = (code) => {
  if (code == null || code === 1) return '';
  return String(code);
};
const getModelCode = (code) => {
  if (code == null || code === '-1') return '';
  return code;
};

export const getLineOptionsByContractLines = (lineData, sins) => {
  return lineData
    .filter((line) => sins.length === 0 || sins.includes(line.sin))
    .map((line) => {
      return {
        value: [
          line.sin,
          getMakeCode(line.makeCode),
          getModelCode(line.modelCode),
          getMakeCode(line.chassisMakeCode),
          getModelCode(line.chassisModelCode),
        ].join('|'),
        label: `${line.scheduleLine} - ${line.makeName} ${line.modelName}`,
      };
    })
    .sort(sortOptByLabel);
};

export const getLineIdOptionsByContractLines = (lineData, sins) => {
  return lineData
    .filter((line) => sins.includes(line.sin))
    .map((line) => {
      return {
        value: `${line.id}`,
        label: `${line.scheduleLine} - ${line.makeName} ${line.modelName}`,
      };
    })
    .sort(sortOptByLabel);
};

export const getSinOptionsByFuelType = (fuelSinData, fuelTypeCodeIds) => {
  if (!fuelTypeCodeIds.length) return [];
  const sins = new Set(
    fuelSinData
      .filter((item) => fuelTypeCodeIds.includes(String(item.fuelTypeCodeId)))
      .map((item) => item.sin),
  );
  return [...sins]
    .map((sin) => ({ value: sin, label: sin }))
    .sort(sortOptByLabel);
};

export const getErrObj = (field, isMissing, value, label) =>
  isMissing ? { [field]: { value, errorMessage: `${label} is required` } } : {};

export const getStep1QueryParams = (formValues) => {
  const queryParams = {
    purchaseTypes:
      formValues.contractType.value === 'SOP'
        ? ['S', 'Y']
        : ['E', 'M', 'N', 'O'],
    contractYears: formValues.contractYears.value.length
      ? formValues.contractYears.value.map((v) => +v)
      : getAllContractYears(),
  };

  if (formValues.isActiveContract) queryParams.isActiveContract = true;

  if (formValues.awardedDateFrom.value)
    queryParams.awardedDateFrom = formValues.awardedDateFrom.value;
  if (formValues.awardedDateTo.value)
    queryParams.awardedDateTo = formValues.awardedDateTo.value;

  return queryParams;
};

const getStep2QueryParams = (formValues) => {
  const queryParams = {};

  if (formValues.solicitations.value.length) {
    const solIds = [];
    formValues.solicitations.value.forEach((v) =>
      solIds.push(...JSON.parse(v)),
    );
    queryParams.solicitationIds = solIds;
  }

  if (formValues.vendors.value.length)
    queryParams.vendorIds = formValues.vendors.value;

  if (formValues.contracts.value.length)
    queryParams.contractNumbers = formValues.contracts.value;

  return {
    ...getStep1QueryParams(formValues),
    ...queryParams,
  };
};

export const getStep2ContractLinesQueryParams = (formValues) => {
  const queryParams = {};

  if (formValues.contractYears.value.length === 1)
    queryParams.contractYear = +formValues.contractYears.value[0];

  return {
    ...getStep2QueryParams(formValues),
    ...queryParams,
  };
};

export const getJSReportQueryParams = (formValues) => {
  const queryParams = {}; // params from second part of step 2

  if (formValues.fuelTypes.value.length)
    queryParams.fuelTypeIds = formValues.fuelTypes.value.map((v) => +v);

  if (formValues.sins.value.length) queryParams.sins = formValues.sins.value;

  if (formValues.contractLines.value.length)
    queryParams.contractLines = formValues.contractLines.value;

  if (formValues.contractLineIds.value.length > 0)
    queryParams.contractLineIds = formValues.contractLineIds.value.map(
      (v) => +v,
    );

  const { year1, period1, year2, period2 } = formValues.contractPeriods;
  if (year1 && period1 && year2 && period2) {
    queryParams.contractYear1 = +year1;
    queryParams.contractPeriod1 = +period1;
    queryParams.contractYear2 = +year2;
    queryParams.contractPeriod2 = +period2;
  }

  return {
    ...getStep2QueryParams(formValues),
    ...queryParams,
  };
};
