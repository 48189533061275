import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  ErrorMessage,
  AFPTable,
  PageTitle,
  Pagination,
  EmptyState,
  Button,
  useModal,
  connectModal,
  Link,
  NotFound,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import pLimit from 'p-limit';
import {
  OPERATIONS,
  SUBJECTS,
  emDashUnicode,
} from '../../../utilities/constants';
import { GET_CONTRACT_HEADER_BY_ID } from '../contract-header/contract-header.gql';
import ContractDetails from '../components/contract-details';
import ToastMessage from '../../../components/Toast/toast';
import { ContractLineListingActions } from './contract-line-listing-actions';
import UpdateLineItemsModal from './update-contract-line-modal';
import DeleteContractLineModal from './delete-items-modal';
import ReviewItemsModal from './review-items-modal';
import { getColumns, RowSubComponent } from './columns';
import {
  GET_CONTRACT_LINES_BY_CRITERIA,
  DELETE_CONTRACT_LINE_BY_ID,
  GET_CONTRACT_LINES_FOR_FILTERS,
} from './lines-query';

import './contract-line-listing.scss';
import ContractLineListingFilter, {
  DEFAULT_FILTERS,
} from './contract-line-listing-search';
import OverlaySpinner from '../../../components/overlay-spinner';
import { isContractSOP } from '../components/contract-helpers';
import { ContractLineListingBreadcrumbs } from './contract-line-listing-breadcrumbs';

const ITEM_PER_PAGE_OPTIONS = [50, 100, 150, 200];

const TableWrapper = ({ tableProps, paginationProps }) => (
  <>
    <AFPTable {...tableProps} key={tableProps.columns.length} />
    {tableProps.data && tableProps.data.length > 0 ? (
      <Pagination {...paginationProps} />
    ) : (
      <div className="text-center margin-top-neg-2 height-full">
        <EmptyState
          hasBackground
          containerStyles="padding-top-9 height-full"
          topText={
            <p aria-label="There are no contract lines associated with this contract.">
              <strong>
                There are no contract lines associated with this contract.
              </strong>
            </p>
          }
        />
      </div>
    )}
  </>
);

TableWrapper.propTypes = {
  tableProps: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  paginationProps: PropTypes.shape({
    itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    onPageChange: PropTypes.func,
    variant: PropTypes.string,
    itemsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    itemsCount: PropTypes.number,
    isReset: PropTypes.bool,
  }).isRequired,
};

const ContractLineListing = () => {
  const { contractHeaderId } = useParams();
  const [alert, setAlert] = useState(null);
  const [order, setOrder] = useState([['scheduleLine', 'ASC']]);
  const [criteria, setCriteria] = useState({ contractHeaderId });
  const [paginationState, setPaginationState] = useState({
    limit: ITEM_PER_PAGE_OPTIONS[0],
    offset: 0,
    currentPage: 1,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [updatedData, setUpdatedData] = useState({});

  const { limit, offset, currentPage } = paginationState;

  const [manualLoading, setManualLoading] = useState(false);
  const [deletingMessage, setDeletingMessage] = useState(null);

  const {
    loading: loadingLines,
    error: errorLines,
    data: dataLines,
    refetch: refetchContractLines,
  } = useQuery(GET_CONTRACT_LINES_BY_CRITERIA, {
    fetchPolicy: 'network-only',
    variables: {
      criteria,
      limit,
      offset,
      order,
    },
  });

  const {
    data: dataLinesForFilters,
    loading: loadingLinesForFilters,
    refetch: refetchLinesForFilters,
  } = useQuery(GET_CONTRACT_LINES_FOR_FILTERS, {
    fetchPolicy: 'network-only',
    skip: !contractHeaderId,
    variables: { contractHeaderId },
  });

  const refetchLines = (newOffset) => {
    if (newOffset == null) refetchContractLines();
    else {
      setPaginationState((prev) => ({
        limit: prev.limit,
        offset: newOffset,
        currentPage: newOffset / prev.limit + 1,
      }));
      refetchContractLines({
        variables: {
          criteria,
          limit,
          offset: newOffset,
          order,
        },
      });
    }
    refetchLinesForFilters();
  };

  const {
    data: dataHeader,
    error: errorHeader,
    refetch: refetchHeader,
    called,
    loading: loadingHeader,
  } = useQuery(GET_CONTRACT_HEADER_BY_ID, {
    variables: { contractHeaderId },
  });

  const [deleteContractLine, { loading: loadingDeleteLines }] = useMutation(
    DELETE_CONTRACT_LINE_BY_ID,
  );

  const contractLinesForFilter =
    dataLinesForFilters?.getContractLinesForFilters ?? [];
  const fetchedContractLines =
    dataLines?.getContractLinesByCriteria?.rows || [];
  const contractLineCount = dataLines?.getContractLinesByCriteria?.count || 0;
  const fetchContractHeaderData = dataHeader?.getContractHeaderById || {};

  const contract = fetchContractHeaderData || {};
  const vendor = fetchContractHeaderData?.vendor || {};
  const vendorId = fetchContractHeaderData?.vendor?.id || '';

  const isSop = isContractSOP(
    fetchContractHeaderData?.solicitation?.purchaseTypeCode,
  );
  const programs = fetchContractHeaderData?.solicitation?.programs || [];

  const [contractLinesData, setContractLinesData] = useState(
    fetchedContractLines,
  );

  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [programDetails, setProgramDetails] = useState({});

  useEffect(() => {
    const programNamesWithIds = programs?.reduce((a, c) => {
      return {
        ...a,
        [c.program]: {
          stdIds: c.solicitationLines.map((item) =>
            String(item.standardItem.standardItemNumber),
          ),
          solLines: c.solicitationLines.map((item) => String(item.id)),
        },
      };
    }, {});
    if (
      programNamesWithIds &&
      Object.keys(programDetails)?.length !== programs.length
    ) {
      setProgramDetails(programNamesWithIds);
    }
  }, [programs]);

  useEffect(() => {
    if (!programs.length) {
      setContractLinesData(fetchedContractLines);
      return;
    }

    const programNamesWithIds = programs?.reduce((a, c) => {
      return {
        ...a,
        [c.program]: {
          stdIds: c.solicitationLines.map((item) =>
            String(item.standardItem.standardItemNumber),
          ),
          solLines: c.solicitationLines.map((item) => String(item.id)),
        },
      };
    }, {});

    const getProgramName = (programNames, { stdId, solId }) => {
      let result;
      if (stdId && solId) {
        result = Object.entries(programNames)
          ?.map(([key, value]) => {
            if (
              value.stdIds?.includes(stdId) &&
              value.solLines?.includes(solId)
            ) {
              return key;
            }
            return null;
          })
          .filter((item) => item)[0];
        return result;
      }
      if (solId) {
        result = Object.entries(programNames)
          ?.map(([key, value]) => {
            if (value.solLines?.includes(solId)) {
              return key;
            }
            return null;
          })
          .filter((item) => item)[0];
        return result;
      }
      if (stdId) {
        result = Object.entries(programNames)
          ?.map(([key, value]) => {
            if (value.stdIds?.includes(stdId)) {
              return key;
            }
            return null;
          })
          .filter((item) => item)[0];
        return result;
      }

      return result;
    };

    setContractLinesData(
      fetchedContractLines.map((line) => {
        return {
          ...line,
          programName: getProgramName(programNamesWithIds, {
            // stdId: line.standardItem.standardItemNumber,
            stdId: null,
            solId: String(line.solicitationLineId),
          }),
        };
      }),
    );
  }, [programDetails, fetchedContractLines]);

  const {
    isOpen: updateContractLineIsOpen,
    openModal: updateContractLineOpenModal,
    closeModal: updateContractLineCloseModal,
  } = useModal();
  const {
    isOpen: reviewItemsIsOpen,
    openModal: reviewItemsOpenModal,
    closeModal: reviewItemsCloseModal,
  } = useModal();
  const {
    isOpen: deleteContractLineIsOpen,
    openModal: deleteContractLineOpenModal,
    closeModal: deleteContractLineCloseModal,
  } = useModal();

  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const ConnectedUpdateLineItemModal = connectModal(UpdateLineItemsModal);
  const ConnectedReviewItemsModal = connectModal(ReviewItemsModal);
  const ConnectedDeleteContractLineModal = connectModal(
    DeleteContractLineModal,
  );

  const onSort = (val) => {
    const [field, sortOrder] = val.split(' ');
    setOrder([[field.split('`')[1], sortOrder]]);
  };

  const onApplyFilters = (isReset) => {
    const newCriteria = { contractHeaderId };
    if (isReset) {
      setCriteria(newCriteria);
      return;
    }
    // eslint-disable-next-line
    for (const key of Object.keys(filters)) {
      if (filters[key]) {
        if (['makeCode', 'chassisMakeCode', 'modelYear'].includes(key))
          newCriteria[key] = +filters[key];
        else newCriteria[key] = filters[key];
      }
    }
    setCriteria(newCriteria);
  };

  const handleRowSelect = ({ selectedFlatRows }) => {
    const rows = selectedFlatRows.map((row) => row.original);
    setSelectedItems(rows);
  };

  const handleDelete = async () => {
    deleteContractLineCloseModal();

    const successItems = [];
    const associatedOrdersItems = [];
    const errorItems = [];

    const promiseLimit = pLimit(3);

    setManualLoading(true);

    const promises = selectedItems.map((item, index) => {
      return promiseLimit(async () => {
        try {
          setDeletingMessage(
            <div className="text-center">
              Deleting line {index + 1} of {selectedItems.length}
            </div>,
          );
          await deleteContractLine({
            variables: { contractLineId: parseInt(item.id, 10) },
          });

          successItems.push(item);
        } catch (err) {
          if (err?.message?.includes('Contract line has existing orders')) {
            associatedOrdersItems.push(item);
          } else {
            errorItems.push(item);
          }
        }
      });
    });

    try {
      await Promise.all(promises);
    } finally {
      setManualLoading(false);
      setDeletingMessage(null);
    }

    if (associatedOrdersItems.length > 0) {
      const scheduleLines = associatedOrdersItems
        .map((item) => item.scheduleLine)
        .join(', ');
      const message =
        associatedOrdersItems.length === 1
          ? `The following line cannot be deleted as there are associated orders: ${scheduleLines}`
          : `The following lines cannot be deleted as there are associated orders: ${scheduleLines}`;
      setAlert({ type: 'error', message });
    }

    if (errorItems.length > 0) {
      const scheduleLines = errorItems
        .map((item) => item.scheduleLine)
        .join(', ');
      const message =
        errorItems.length === 1
          ? `Unable to delete the following contract line ${scheduleLines}. Please try again later.`
          : `Unable to delete the following contract lines ${scheduleLines}. Please try again later.`;
      setAlert({ type: 'error', message });
    }

    if (successItems.length > 0) {
      const scheduleLines = successItems
        .map((item) => item.scheduleLine)
        .join(', ');
      const message =
        successItems.length === 1
          ? `The contract line ${scheduleLines} has been deleted.`
          : `The contract lines ${scheduleLines} have been deleted.`;
      setAlert({ type: 'success', message });

      const newLineCount = contractLineCount - successItems.length;
      if (newLineCount > 0 && newLineCount <= paginationState.offset) {
        const newOffset =
          (Math.ceil(newLineCount / paginationState.limit) - 1) *
          paginationState.limit;
        refetchLines(newOffset);
      } else refetchLines();
    }
  };

  const columns = useMemo(() => getColumns({ isSop }), [isSop]);

  if (!called) {
    return null;
  }

  if (errorLines) {
    return (
      <div id="contract-line-listing">
        <ContractLineListingBreadcrumbs />
        {errorLines && (
          <ErrorMessage>An error occurred: {errorLines.message}</ErrorMessage>
        )}
      </div>
    );
  }

  if (called && !loadingHeader && !dataHeader?.getContractHeaderById) {
    return (
      <div id="contract-line-listing">
        <ContractLineListingBreadcrumbs />
        <NotFound />
      </div>
    );
  }

  return (
    <div id="contract-line-listing">
      {/* <StandardItemBodyChassisPanel hasChassis /> */}
      <ContractLineListingBreadcrumbs />
      {(loadingHeader ||
        loadingLines ||
        loadingLinesForFilters ||
        loadingDeleteLines ||
        manualLoading) && <OverlaySpinner message={deletingMessage} />}
      {errorLines && (
        <ErrorMessage>An error occurred: {errorLines.message}</ErrorMessage>
      )}
      {alert && (
        <ToastMessage
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
          closable
          className="margin-bottom-2"
        />
      )}
      {!loadingHeader &&
        !errorHeader &&
        fetchContractHeaderData &&
        !errorLines &&
        dataLines && (
          <>
            <div className="grid-row grid-gap margin-bottom-2">
              <div className="grid-col-10">
                <PageTitle
                  title={
                    <>
                      Contract uPIID:{' '}
                      {fetchContractHeaderData.contractUpiid ||
                        fetchContractHeaderData.solicitation
                          ?.solicitationNumber ||
                        emDashUnicode}
                    </>
                  }
                />
                <div>
                  <span>Contractor: </span>
                  <Link
                    href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${vendorId}`}
                  >
                    {vendor.vendorName}
                  </Link>
                </div>
              </div>
              <div className="grid-col-2 display-flex flex-align-end flex-justify-end">
                <ContractLineListingActions
                  contractHeader={fetchContractHeaderData}
                  setAlert={setAlert}
                  refetchLines={refetchLines}
                  refetchHeader={refetchHeader}
                />
              </div>
            </div>
            <ContractDetails contract={contract} />
            <ContractLineListingFilter
              contractLines={contractLinesForFilter}
              filters={filters}
              setFilters={setFilters}
              onApplyFilters={onApplyFilters}
              isSop={!!isSop}
            />
            <div className="grid-row grid-gap margin-top-2 margin-bottom-2">
              {canUpdateContract && (
                <div className="grid-col-12">
                  {isSop && (
                    <Button
                      label="Update selected lines"
                      variant="primary"
                      onClick={updateContractLineOpenModal}
                      disabled={selectedItems.length === 0}
                    />
                  )}
                  <Button
                    label="Delete selected lines"
                    variant="danger"
                    onClick={deleteContractLineOpenModal}
                    disabled={selectedItems.length === 0}
                    className="margin-left-2"
                  />
                </div>
              )}
            </div>
            <TableWrapper
              tableProps={{
                data: contractLinesData,
                columns,
                // defaultSort: 'scheduleLine asc',
                onSort,
                defaultSort: order,
                expandable: true,
                selectable: canUpdateContract,
                onRowSelect: handleRowSelect,
                renderRowSubComponent: (props) => (
                  <RowSubComponent {...props} isSop={isSop} />
                ),
              }}
              paginationProps={{
                itemsPerPageOptions: ITEM_PER_PAGE_OPTIONS,
                onPageChange: (newPage, itemsPerPage) => {
                  setPaginationState({
                    ...paginationState,
                    currentPage: newPage,
                    offset: (newPage - 1) * itemsPerPage,
                    limit: itemsPerPage,
                  });
                },
                variant: 'advanced',
                itemsPerPage: limit,
                currentPage,
                itemsCount: contractLineCount,
                isReset: false,
              }}
            />

            <ConnectedUpdateLineItemModal
              isOpen={updateContractLineIsOpen}
              closeModal={updateContractLineCloseModal}
              lineItems={selectedItems}
              contractHeaderId={contractHeaderId}
              headerData={contract}
              onComplete={(formData, items, data) => {
                const { errors, updatedContractLines } =
                  data?.updateMultipleContractLines || {};
                setUpdatedData({
                  formData,
                  items,
                  errors,
                  updatedContractLines,
                });

                setAlert({
                  type: updatedContractLines.length ? 'success' : 'error',
                  message: (
                    <div>
                      <span className="body-bold">
                        {updatedContractLines.length > 0
                          ? `${updatedContractLines.length} contract lines have been
                      updated successfully`
                          : ''}
                        {updatedContractLines.length > 0 && errors.length > 0
                          ? ' and '
                          : ' '}
                        {errors.length > 0
                          ? `${errors.length} contract lines could not be updated`
                          : ''}
                        {'. '}
                      </span>
                      <Button
                        onClick={reviewItemsOpenModal}
                        variant="unstyled"
                        label="Review updates"
                        style={{ padding: 0 }}
                      />
                    </div>
                  ),
                });
                refetchLines();
              }}
            />

            <ConnectedReviewItemsModal
              isOpen={reviewItemsIsOpen}
              closeModal={reviewItemsCloseModal}
              items={updatedData?.items}
              data={updatedData?.formData}
              errors={updatedData?.errors}
              updatedContractLines={updatedData?.updatedContractLines}
            />
            <ConnectedDeleteContractLineModal
              isOpen={deleteContractLineIsOpen}
              closeModal={deleteContractLineCloseModal}
              onDelete={handleDelete}
              selectedItems={selectedItems}
            />
          </>
        )}
    </div>
  );
};

export default ContractLineListing;
