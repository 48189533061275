export const REPORTS_ALL = {
  SOP: [
    'contractPricing',
    'assemblyPointInspectionPoint',
    'contractsAwarded',
    'contractPriceComparison', // SOP only
    'contractEngineAndFuel', // SOP only
    'contractEngineAndFuelDelivery', // SOP only
    'contractStatus',
    'fuelUnknown', // SOP only
    // 'contractLineVersionComparison',
    'contractLineListing',
    'contractLineTemplate', // SOP only
    'contractConflicts', // SOP only
  ],
  'Non SOP': [
    // 'contractPricing', // disabled for non-SOP
    'assemblyPointInspectionPoint',
    'contractsAwarded',
    'contractStatus',
    'contractLineListingNonSOP',
  ],
};
export const REPORTS_VS = {
  SOP: [
    'contractPricing',
    'contractPriceComparison', // SOP only
    'contractEngineAndFuelDelivery', // SOP only
    'contractStatus',
    'contractLineListing',
    'contractLineTemplate', // SOP only
    'fuelUnknown', // SOP only
    'contractConflicts', // SOP only
  ],
  'Non SOP': [
    // 'contractPricing', // disabled for non-SOP
    'contractStatus',
    'contractLineListingNonSOP',
  ],
};

export const getContractTypes = (reports) => Object.keys(reports);

export const FT_XLSX = 'XLSX';
export const FT_PDF = 'PDF';
export const DOWNLOAD_BUTTON = {
  [FT_XLSX]: { label: 'Excel (.xlsx)', icon: 'excel_icon' },
  [FT_PDF]: { label: 'PDF (.pdf)', icon: 'pdf' },
};

export const REPORT_CONFIG = {
  contractPricing: {
    reportTypeId: 602,
    label: 'Contract pricing report',
    fileTypes: [FT_XLSX, FT_PDF],
    description: '',
    contractYearSS: true,
    solVendorSS: true,
    contractMS: true, // optional
    contractLineMS: true, // optional
  },
  assemblyPointInspectionPoint: {
    reportTypeId: 601,
    label: 'Assembly point/Inspection point summary',
    fileTypes: [FT_XLSX],
    description: '',
    contractYearSS: true,
    solVendorSS: true,
    contractMS: true, // optional
  },
  contractsAwarded: {
    reportTypeId: 603,
    label: 'Contracts awarded report',
    fileTypes: [FT_XLSX],
    description: '',
    awardedDateRange: true,
    solVendorMS: true, // optional
  },
  contractEngineAndFuel: {
    reportTypeId: 604,
    label: 'Contract engine and fuel report',
    fileTypes: [FT_XLSX],
    description: '',
    contractYearMS: true,
    fuelTypeMS: true,
    sinMS: true, // optional
  },
  contractEngineAndFuelDelivery: {
    reportTypeId: 605,
    label: 'Contract engine and fuel delivery report',
    fileTypes: [FT_XLSX],
    description: '',
    contractYearSS: true,
    solVendorSS: true,
    sinSS: true, // optional
    contractMS: true, // optional
  },
  contractStatus: {
    reportTypeId: 606,
    label: 'Contract status report',
    fileTypes: [FT_XLSX],
    description: '',
    isActiveContract: true,
    solVendorMS: true, // optional
  },
  contractPriceComparison: {
    reportTypeId: 607,
    label: 'Contract price comparison report',
    fileTypes: [FT_XLSX],
    description: '',
    solVendorSS: true,
    contractSS: true,
    constractPeriodDS: true,
    sinSS: true, // optional
    contractLineMS: true, // optional
  },
  fuelUnknown: {
    reportTypeId: 608,
    label: 'Fuel unknown report',
    fileTypes: [FT_XLSX],
    description: '',
    isActiveContract: true,
    solVendorSS: true,
    contractMS: true, // optional
  },
  // contractLineVersionComparison: {
  //   reportTypeId: 609,
  //   label: 'Contract line version comparison report',
  //   fileTypes: [FT_XLSX, FT_PDF],
  //   description: '',
  //   solVendorSS: true,
  // },
  contractLineListing: {
    reportTypeId: 610,
    label: 'Contract line listing report',
    fileTypes: [FT_XLSX],
    description: '',
    contractYearSS: true,
    solVendorSS: true,
    contractSS: true,
  },
  contractLineListingNonSOP: {
    reportTypeId: 611,
    label: 'Contract line listing report',
    fileTypes: [FT_XLSX],
    description: '',
    contractYearSS: true,
    solVendorSS: true,
    contractSS: true,
  },
  contractLineTemplate: {
    reportTypeId: 612,
    label: 'Contract line template report',
    fileTypes: [FT_XLSX],
    description: '',
    contractYearSS: true,
    solVendorSS: true,
    contractSS: true,
    sinSS: true, // required
    isSinSSrequired: true,
    contractLineIdMS: true, // optional
  },
  contractConflicts: {
    reportTypeId: 613,
    label: 'Contract option conflicts report',
    fileTypes: [FT_XLSX],
    description: '',
    contractYearSS: true,
    solVendorSS: true,
    contractMS: true, // optional
  },
};

export const API_TIME_LIMIT = 30000; // ms (30 seconds)

const STEP_LABELS = ['Contract selection', 'Solicitation or vendor selection'];
export const STEPS = [
  {
    heading: { current: 1, text: STEP_LABELS[0] },
    steps: [
      { label: STEP_LABELS[0], status: 'current' },
      { label: STEP_LABELS[1], status: 'not completed' },
    ],
  },
  {
    heading: { current: 2, text: STEP_LABELS[1] },
    steps: [
      { label: STEP_LABELS[0], status: 'completed' },
      { label: STEP_LABELS[1], status: 'current' },
    ],
  },
];
