/* eslint-disable react/prop-types */
import React from 'react';
import { AFPTableRowAction } from '@gsa/afp-component-library';
import { ReadyIcon } from '../../../bid-dashboard/bid-line-details/components/tags';
import PhotoCell from './photo-cell';
import DownloadableDocumentCell from './downloadable-doc-cell';
import ImageOrderUpDown from './image-order-up-down';

export const DELETE_ACTION = 'Delete';

export const MARK_REP_IMG_ACTION = 'Mark as the representative image in Store';

const documentActions = [
  {
    icon: 'cancel',
    label: DELETE_ACTION,
  },
];

export const getDocumentReadOnlyColumns = () => [
  {
    Header: <span className="margin-left-4">Name</span>,
    accessor: 'documentMetadataId',
    sortable: false,
    Cell: ({ value, row }) => {
      return (
        <DownloadableDocumentCell
          documentMetadataId={value}
          docName={row?.original?.name}
        />
      );
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
    sortable: false,
  },
];

export const getDocumentColumns = (onActionClick, readOnly) => {
  const cols = [
    {
      Header: <span className="margin-left-4">Name</span>,
      accessor: 'id',
      sortable: false,
      Cell: ({ value }) => {
        return <DownloadableDocumentCell id={value} />;
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      sortable: false,
    },
  ];
  if (!readOnly)
    cols.push({
      Header: 'Actions',
      sortable: false,
      Cell: ({ row }) => {
        return (
          <AFPTableRowAction
            row={row}
            actions={documentActions}
            onSelectAction={(action) => onActionClick(action, row)}
          />
        );
      },
    });
  return cols;
};

const photoActions = [
  ...documentActions,
  {
    icon: 'check',
    label: MARK_REP_IMG_ACTION,
  },
];

export const getPhotosReadonlyColumns = () => [
  {
    Header: 'Thumbnail',
    accessor: 'id',
    sortable: false,
    Cell: ({ value }) => {
      return <PhotoCell id={value} />;
    },
  },
  {
    Header: <span className="margin-left-4">Name</span>,
    accessor: 'name',
    sortable: false,
  },
  {
    Header: 'Representative image in Store',
    accessor: 'representativeImage',
    sortable: false,
    Cell: ({ value }) => (value === true ? <ReadyIcon isReady /> : null),
  },
  {
    Header: 'Description',
    accessor: 'description',
    sortable: false,
  },
  {
    accessor: 'sequenceNumber',
    sortable: false,
    Cell: () => null,
  },
];

export const getPhotoColumns = (onActionClick, disableAction) => {
  const cols = [
    {
      Header: 'Thumbnail',
      accessor: 'id',
      sortable: false,
      Cell: ({ value }) => {
        return <PhotoCell id={value} />;
      },
    },
    {
      Header: <span className="margin-left-4">Name</span>,
      accessor: 'name',
      sortable: false,
    },
    {
      Header: 'Representative image in Store',
      accessor: 'representativeImage',
      sortable: false,
      Cell: ({ value }) => (value === true ? <ReadyIcon isReady /> : null),
    },
    {
      Header: 'Description',
      accessor: 'description',
      sortable: false,
    },
  ];
  if (!disableAction)
    cols.push(
      {
        Header: 'Order',
        accessor: 'sequenceNumber',
        sortable: false,
        Cell: ImageOrderUpDown,
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: ({ row, rows }) => {
          return (
            <AFPTableRowAction
              row={row}
              actions={photoActions}
              onSelectAction={(action) => onActionClick(action, row, rows)}
            />
          );
        },
      },
    );
  return cols;
};
