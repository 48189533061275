import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Fieldset, Button, TextInput } from '@gsa/afp-component-library';
import { useContractLine } from '../provider/contract-line-provider';
import { ReadOnlyWrapper } from '../helpers/ec-components';

function generateRandomId(length = 10) {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters[randomIndex];
  }

  return `local_${randomId}`;
}

const Clarifications = ({ clarificationType }) => {
  const {
    contractLine,
    detailTabData,
    setDetailTabData,
    canUpdateContract,
  } = useContractLine();

  const existingClarifications = useMemo(() => {
    const filterer = (c) => c.clarificationType === clarificationType;
    // sort by clarification sequenceNumber

    let cs = detailTabData?.clarifications?.filter(filterer);
    if (!cs?.length) {
      cs = contractLine?.contractLineClarifications?.filter(filterer);
    }
    if (!cs || cs.length < 1) {
      cs = [{ id: generateRandomId(), clarification: '', clarificationType }];
    }
    cs?.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    return cs;
  }, [detailTabData, contractLine, clarificationType]);

  const clonedExisitngClarifications = existingClarifications?.length
    ? JSON.parse(JSON.stringify(existingClarifications))
    : [];

  const [textareas, setTextareas] = useState(clonedExisitngClarifications);

  const setTextareValue = (index, value) => {
    const newTextareas = [...textareas];
    newTextareas[index] = { ...textareas[index], clarification: value };
    setTextareas(newTextareas);
  };

  const addTextarea = () => {
    const newTextArea = {
      id: generateRandomId(),
      clarification: '',
      clarificationType,
    };
    const newTextareas = [...textareas];
    newTextareas[textareas.length] = newTextArea;
    setTextareas(newTextareas);
  };

  const updateProvider = useCallback(
    (clarifications) => {
      const notRelatedEntries =
        (
          detailTabData?.clarifications ||
          contractLine?.contractLineClarifications
        )?.filter((x) => x.clarificationType !== clarificationType) || [];
      setDetailTabData((prev) => ({
        ...prev,
        clarifications: [...notRelatedEntries, ...clarifications],
      }));
    },
    [detailTabData, contractLine, clarificationType],
  );

  const removeClarification = (id) => {
    const newTextAreas = textareas.filter((x) => x.id !== id);
    setTextareas(newTextAreas);
    updateProvider(newTextAreas);
  };

  return (
    <Fieldset
      name="clarification"
      data-testid={
        clarificationType === 'C' ? 'clarifications' : 'technical-exceptions'
      }
    >
      <>
        {textareas.map((item, index) => {
          return (
            <div className="grid-row flex-row" key={item.id}>
              <div className="grid-col-4">
                <ReadOnlyWrapper wrapperType="list">
                  <TextInput
                    id={index}
                    value={item.clarification}
                    help={
                      clarificationType === 'C'
                        ? 'Provide clarification for this contract line'
                        : 'Provide explanation of any non-compliance for this contract line'
                    }
                    data-testid={
                      clarificationType === 'C'
                        ? `clarifications-input-${index}`
                        : `technical-exceptions-input-${index}`
                    }
                    type="textarea"
                    characterLimit={2000}
                    onChange={(e) => setTextareValue(index, e.target.value)}
                    onBlur={() => updateProvider(textareas)}
                    disabled={!canUpdateContract}
                  />
                </ReadOnlyWrapper>
              </div>
              <div className="grid-col-8 flex-align-start">
                {canUpdateContract && textareas.length > 1 && (
                  <Button
                    variant="outline"
                    onClick={() => removeClarification(item.id)}
                    leftIcon={{ name: 'close' }}
                    style={{
                      height: 50,
                      width: 50,
                      margin: '134px 0 0 8px',
                      padding: 0,
                    }}
                    size="medium"
                    data-testid={
                      clarificationType === 'C'
                        ? `clarifications-${index}-remove-btn`
                        : `technical-exceptions-${index}-remove-btn`
                    }
                    disabled={!canUpdateContract}
                  />
                )}
              </div>
            </div>
          );
        })}
        {canUpdateContract && (
          <Button
            variant="unstyled"
            size="medium"
            label={`Add ${
              clarificationType === 'C'
                ? 'clarification'
                : 'technical exception'
            }`}
            leftIcon={{ name: 'add' }}
            onClick={addTextarea}
            className="margin-top-1"
            data-testid={
              clarificationType === 'C'
                ? 'add-clarification'
                : 'add-technical-exception'
            }
          />
        )}
      </>
    </Fieldset>
  );
};

Clarifications.propTypes = {
  clarificationType: PropTypes.oneOf(['C', 'E']),
};

Clarifications.defaultProps = {
  clarificationType: 'C',
};

export default Clarifications;
