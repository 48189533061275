import { gql } from '@apollo/client';

export const GET_CONTRACT_LINE_BY_ID = gql`
  query getContractLineTemplateById($contractLineId: Float!) {
    getContractLineTemplateById(contractLineId: $contractLineId) {
      id
      participant1122Prog
      isDirect
      shipmentDays
      shipmentJustification
      scheduleLine
      modelYear
      orderStartDate
      orderEndDate
      additionalInformation
      quantity
      isPurchaseAvailable
      shipmentOrDelivery
      manufacturerModelNumber
      deliveryDate
      vin
      coFirstName
      coLastName
      coEmail
      associatedLocations {
        id
        vendorAssemblyPointId
        vendorInspectionPointId
        percentageOfDomContent
      }
      contractLineFinancialRef {
        unitPrice
        destinationCharge
        invoicePrice
        asePrice
        msrp
        estimatedFleetQuantity
        estimatedTotalQuantity
      }
      contractHeader {
        contractHeaderId
        contractNumber
        contractYear
        renewalYear
        fleetAwarded
        publishedInFleet
        contractUpiid
        contractEndDate
        solicitationId
        solicitationPeriodId
        solicitation {
          purchaseTypeCode
          contractYear
          solicitationNumber
          solicitationPeriods {
            id
            startDate
            endDate
            periodType
          }
          programs {
            id
            program
            solicitationLines {
              id
              standardItem {
                id
                standardItemNumber
                status
                title
                tags
              }
            }
          }
        }
        vendor {
          id
          vendorName
          uniqueEntityIdentifier
        }
      }
      contractLineClarifications {
        id
        clarification
        clarificationType
        sequenceNumber
      }
      standardItemId
      standardItem {
        standardItemId: id
        standardItemNumber
        title
        year
        content {
          content
        }
        tags
      }
      bidLineId
      makeCode
      make {
        makeName
        makeCode
        approvalStatus
        fleetStatus
      }
      modelCode
      model {
        modelName
        modelCode
        approvalStatus
        fleetStatus
      }
      modelYear
      chassisMakeCode
      chassisMake {
        makeName
        makeCode
        approvalStatus
        fleetStatus
      }
      chassisModelCode
      chassisModel {
        modelName
        modelCode
        approvalStatus
        fleetStatus
      }
      makeModelApproval {
        fleetApprovalStatus
      }
      contractLineEcs {
        id
        contractLineId
        siEcAssociationId
        equipmentCode
        inputType
        comply
        value
        optionType
        maxQuantity
        unitPrice
        additionalShippingDays
        contractLineEcClarification {
          id
          clarification
          clarificationType
        }
        contractLineEcRelation {
          id
          contractLineEcId
          siEcAssociationId
          equipmentCode
          relationShipCode
          relatedSiEcAssociationId
          sequenceNumber
        }
      }
      contractLineEngines {
        id
        siEcAssociationId
        cylinders
        engineLitres
        engineModel
        isLowGHG
        fuelTypeCodeId
        isFuelDataUnknown
        range
        rangeElectric
        deliveryRegionType
        deliveryStates
        isMetric
        isGPTKM
        mpgs {
          id
          fuelType
          type
          value
        }
        charging {
          id
          type
          isFastChargeCapable
          fastChargeTypes {
            id
            fastChargeType
            userEnteredType
            chargeOption
          }
          bkwh
        }
        contractLineEngineClarification {
          clarification
          clarificationType
        }
      }

      modelColors {
        id
        vendorColorName: description
        vendorColorCode: colorCode
        gsaColorCodeId
        standardPremiumCode
        price
      }

      contractLineAttachments {
        id
        contractLineId
        name
        documentType
        documentMetadataId
        signedUrl
        description
        sequenceNumber

        clAttachmentMetadata {
          fileLocation
          fileMimeType
          scanStatus
        }
      }
    }
  }
`;

export const GET_EQUIPMENT_OPTIONS = gql`
  query getEquipmentOptions($standardItemId: Float!, $getActive: Boolean) {
    getEquipmentOptions(
      standardItemId: $standardItemId
      getActive: $getActive
    ) {
      id
      associationTypeCode
      associationText
      inputTypeCode
      lowerBound
      upperBound
      criteriaCode
      unitCode
      preDefinedValue
      equipmentCodeId
      equipmentCode {
        code
        category
        title
        quantityRequired
        sequence
        tags
        categoryCode {
          code
          title
          additionalProps
        }
        unitCode {
          code
          standardsCodesCR {
            code
          }
          requestStatus
        }
      }
      comments {
        comment
      }
    }
  }
`;

export const GET_GSA_CONSTANTS = gql`
  query getGsaConstants {
    gsaColors: getGSAColors {
      id
      code
      title
    }
    fuelTypes: getFuelTypes(isForBids: true) {
      id
      code
      title
    }
    states: getStates {
      stateCode
      stateName
      isoCountryCode2
    }
  }
`;

export const CREATE_CONTRACTLINE_ASSO_LOC = gql`
  mutation CreateContractLineAssociatedLocation(
    $contractLineAssociatedLocationInput: ContractLineAssociatedLocationInput!
  ) {
    createContractLineAssociatedLocation(
      contractLineAssociatedLocationInput: $contractLineAssociatedLocationInput
    ) {
      id
      vendorAssemblyPointId
      vendorInspectionPointId
      percentageOfDomContent
    }
  }
`;

export const REMOVE_CONTRACTLINE_ASSO_LOC = gql`
  mutation RemoveContractLineAssociatedLocation(
    $removeContractLineAssociatedLocationId: Float!
  ) {
    removeContractLineAssociatedLocation(
      id: $removeContractLineAssociatedLocationId
    )
  }
`;

export const SAVE_CONTRACT_LINE_BY_ID = gql`
  mutation saveContractLineById(
    $contractLineId: Float!
    $contractLineInput: ContractLineInput!
    $modTag: String
  ) {
    saveContractLineById(
      contractLineId: $contractLineId
      contractLineInput: $contractLineInput
      modTag: $modTag
    ) {
      contractLine {
        scheduleLine
        shipmentDays
        shipmentJustification
        participant1122Prog
      }
      error {
        lineTemplateErrors {
          lineTabErrors {
            fieldName
            message
          }
          lineDetailTabErrors {
            fieldName
            message
          }
          minReqTabErrors {
            ecCode
            message
          }
          optEqTabErrors {
            ecCode
            message
          }
          engineTabErrors {
            ecCode
            message
          }
          colorsTabErrors {
            colorCode
            errors {
              fieldName
              message
            }
          }
          documentsTabError
        }
      }
    }
  }
`;

export const GET_CONTRACT_LINE_VERSION_WITH_HISTORY = gql`
  query GetContractLineVersionWithHistory(
    $versionNumber: Float!
    $contractLineId: Float!
  ) {
    getContractLineVersionWithHistory(
      versionNumber: $versionNumber
      contractLineId: $contractLineId
    ) {
      id
      contractHeaderVersion {
        # NOTE: Contract header needed for latest version
        contractHeader {
          contractNumber
          contractYear
          fleetAwarded
          contractUpiid
          contractEndDate
          solicitationId
          solicitationPeriodId
          solicitation {
            purchaseTypeCode
            contractYear
            solicitationNumber
            solicitationPeriods {
              id
              startDate
              endDate
              periodType
            }
            programs {
              id
              program
              solicitationLines {
                id
                standardItem {
                  id
                  standardItemNumber
                  status
                  title
                  tags
                }
              }
            }
          }
          vendor {
            id
            vendorName
            uniqueEntityIdentifier
          }
        }
        # NOTE: Contract header needed for history version
        contractHeaderHistory {
          contractNumber
          contractYear
          fleetAwarded
          contractUpiid
          contractEndDate
          solicitationId
          solicitationPeriodId
          solicitation {
            purchaseTypeCode
            contractYear
            solicitationNumber
            solicitationPeriods {
              id
              startDate
              endDate
              periodType
            }
            programs {
              id
              program
              solicitationLines {
                id
                standardItem {
                  id
                  standardItemNumber
                  status
                  title
                  tags
                }
              }
            }
          }
          vendor {
            id
            vendorName
            uniqueEntityIdentifier
          }
        }
      }
      contractLineHistory {
        id
        participant1122Prog
        isDirect
        shipmentDays
        shipmentJustification
        scheduleLine
        modelYear
        orderStartDate
        orderEndDate
        additionalInformation
        quantity
        isPurchaseAvailable
        shipmentOrDelivery
        manufacturerModelNumber
        deliveryDate
        vin
        coFirstName
        coLastName
        coEmail
        associatedLocations {
          id
          vendorAssemblyPointId
          vendorInspectionPointId
          percentageOfDomContent
        }
        contractLineFinancialRef {
          unitPrice
          destinationCharge
          invoicePrice
          asePrice
          msrp
          estimatedFleetQuantity
          estimatedTotalQuantity
        }
        contractLineClarifications {
          id
          clarification
          clarificationType
          sequenceNumber
        }
        standardItemId
        standardItem {
          standardItemId: id
          standardItemNumber
          title
          year
          content {
            content
          }
          tags
        }
        make {
          makeName
          makeCode
          approvalStatus
          fleetStatus
        }
        model {
          modelName
          modelCode
          approvalStatus
          fleetStatus
        }
        chassisMake {
          makeName
          makeCode
          approvalStatus
          fleetStatus
        }
        chassisModel {
          modelName
          modelCode
          approvalStatus
          fleetStatus
        }
        makeModelApproval {
          fleetApprovalStatus
        }
        contractLineEcs {
          id
          siEcAssociationId
          equipmentCode
          inputType
          comply
          value
          optionType
          maxQuantity
          unitPrice
          additionalShippingDays
          contractLineEcClarification {
            id
            clarification
            clarificationType
          }
          contractLineEcRelation {
            id
            contractLineEcId
            siEcAssociationId
            equipmentCode
            relationShipCode
            relatedSiEcAssociationId
            sequenceNumber
          }
        }
        contractLineEngines {
          id
          siEcAssociationId
          cylinders
          engineLitres
          engineModel
          isLowGHG
          fuelTypeCodeId
          isFuelDataUnknown
          range
          rangeElectric
          deliveryRegionType
          deliveryStates
          isMetric
          isGPTKM
          horsePower
          mpgs {
            id
            fuelType
            type
            value
          }
          charging {
            id
            type
            isFastChargeCapable
            fastChargeTypes {
              id
              fastChargeType
              userEnteredType
              chargeOption
            }
            bkwh
          }
          contractLineEngineClarification {
            clarification
            clarificationType
          }
        }
        modelColors {
          id
          vendorColorName: description
          vendorColorCode: colorCode
          gsaColorCodeId
          standardPremiumCode
          price
        }
        contractLineAttachments {
          id
          name
          documentType
          documentMetadataId
          signedUrl
          description
          sequenceNumber

          clAttachmentMetadata {
            fileLocation
            fileMimeType
            scanStatus
          }
        }
        orderStartDate
        orderEndDate
        originCity
        originStateId
        originState {
          id
        }
        originProvince
        originCountryId
        shipmentDays
        shipmentJustification
        shipmentOrDelivery
        fob
        inspectionGsaRegNum
        renewLine
        is50StateCompliant
        isLeaseAvailable
        isPurchaseAvailable
        availableForOrdering
        participant1122Prog
        isDirect
        quantity
        additionalInformation
        comment
        manufacturerModelNumber
        deliveryDate
        vin
        coFirstName
        coLastName
        coEmail
        unitPrice
        destinationCharge
        invoicePrice
        asePrice
        msrp
        estimatedFleetQuantity
        estimatedTotalQuantity
        afvBase
        historyCreatedByUser {
          id
        }
        bidLineId
        makeCode
        modelCode
        modelYear
        chassisMakeCode
        chassisModelCode
        createdByUser
        createdAt
        updatedByUser
        updatedAt
        deletedAt
        dmlFlag
        dataSource
      }
    }
  }
`;
