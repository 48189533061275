import React, { useState, useMemo } from 'react';
import * as yup from 'yup';
import { TextInput, SelectDropdown } from '@gsa/afp-component-library';
import { INPUT_DROPDOWN_DEFAULT_OPTION } from '../../../bid-dashboard/bid-line-details/provider/helpers';
import { useContractLine } from '../provider/contract-line-provider';
import { isLineDetailTabFieldrequired } from './fields-requirement';
import { ReadOnlyWrapper } from '../helpers/ec-components';

const VehicleInfoSection = () => {
  const {
    contractLine,
    detailTabData,
    setDetailTabData,
    isAwardedInFleet,
    canUpdateContract,
  } = useContractLine();

  const initialFields = useMemo(() => {
    const fields = {
      shipmentDays:
        detailTabData.shipmentDays || contractLine.shipmentDays || null,
      shipmentJustification:
        detailTabData.shipmentJustification ||
        contractLine.shipmentJustification ||
        null,
      participant1122Prog: null,
    };
    if (detailTabData.participant1122Prog !== null) {
      fields.participant1122Prog = detailTabData.participant1122Prog;
    } else if (contractLine.participant1122Prog !== null) {
      fields.participant1122Prog = contractLine.participant1122Prog;
    }
    return fields;
  }, [contractLine, detailTabData]);

  const [
    { shipmentDays, shipmentJustification, participant1122Prog },
    setFieldsData,
  ] = useState(initialFields);

  const [shippingTimeErrorMessage, setShippingTimeErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validationSchema = yup.object().shape({
    participant1122Prog: yup.string().required('1122 Program is required.'),
    shipmentDays: yup
      .number()
      .integer('Shipping days must be a whole number.')
      .moreThan(0, 'Shipping days must be between 0 and 999.')
      .lessThan(1000, 'Shipping days must be between 0 and 999.')
      .required('Shipping days is required.'),
    shipmentJustification: yup.string(),
  });

  const options = [INPUT_DROPDOWN_DEFAULT_OPTION];
  options.push({ label: 'Yes', value: true }, { label: 'No', value: false });

  const genLabel = (label) => (
    <span className="text-bold" data-testid={label}>
      {label}
    </span>
  );

  const handleBlur = async (event) => {
    const { name, value } = event.target;
    if (contractLine?.[name] === value) {
      return;
    }
    if (
      name === 'shipmentDays' &&
      (value === '' || Number.isNaN(Number(value)))
    ) {
      setShippingTimeErrorMessage('Shipping days is required.');
      return;
    }
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setShippingTimeErrorMessage('');
      setErrorMessage('');
    } catch (error) {
      if (name === 'shipmentDays') {
        setShippingTimeErrorMessage(error.message);
      } else {
        setErrorMessage(error.message);
      }
    }
    setDetailTabData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFieldsData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onSelectDropdownChange = (event) => {
    onChange(event);
    handleBlur(event);
  };

  return (
    <>
      <section>
        <div className="width-card-lg ">
          <ReadOnlyWrapper dataType="boolean">
            <SelectDropdown
              label={genLabel(
                'Do you want this line to be a part of the 1122 program?',
              )}
              name="participant1122Prog"
              data-testid="1122_program_yes_no"
              value={
                participant1122Prog === null
                  ? null
                  : Boolean(participant1122Prog)
              }
              errorMessage={errorMessage}
              options={options}
              onChange={onSelectDropdownChange}
              required={isLineDetailTabFieldrequired(
                isAwardedInFleet,
                'participant1122Prog',
              )}
              disabled={!canUpdateContract}
            />
          </ReadOnlyWrapper>
        </div>
      </section>

      <div className="grid-row flex-wrap">
        <div className="grid-col flex-5">
          <div className="width-card-lg">
            <ReadOnlyWrapper>
              <TextInput
                name="shipmentDays"
                min={0}
                max={999}
                label={genLabel('Shipping days')}
                help="In days"
                data-testid="shipping-days"
                type="number"
                errorMessage={shippingTimeErrorMessage}
                value={shipmentDays}
                onChange={onChange}
                onBlur={handleBlur}
                required={isLineDetailTabFieldrequired(
                  isAwardedInFleet,
                  'shipmentDays',
                )}
                disabled={!canUpdateContract}
              />
            </ReadOnlyWrapper>
          </div>
          <ReadOnlyWrapper>
            <TextInput
              className="margin-0"
              name="shipmentJustification"
              data-testid="shipmentJustification"
              label={
                <span className="text-bold">{genLabel('Justification')}</span>
              }
              help="Add a justification for outside the shipping timeline."
              type="textarea"
              characterLimit={2000}
              value={shipmentJustification}
              onChange={onChange}
              onBlur={handleBlur}
              required={isLineDetailTabFieldrequired(
                isAwardedInFleet,
                'shipmentJustification',
              )}
              disabled={!canUpdateContract}
            />
          </ReadOnlyWrapper>
        </div>
      </div>
    </>
  );
};

export default VehicleInfoSection;
