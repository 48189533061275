import { gql } from '@apollo/client';

export const GET_CONTRACT_JS_REPORT = gql`
  query getContractJSReport($input: JSReportInput!) {
    getContractJSReport(input: $input)
  }
`;

export const GET_CONTRACTS_FOR_REPORT = gql`
  query getContractsForReport(
    $purchaseTypes: [String!]!
    $contractYears: [Float!]!
    $isActiveContract: Boolean
    $awardedDateFrom: DateTime
    $awardedDateTo: DateTime
  ) {
    getContractsForReport(
      purchaseTypes: $purchaseTypes
      contractYears: $contractYears
      isActiveContract: $isActiveContract
      awardedDateFrom: $awardedDateFrom
      awardedDateTo: $awardedDateTo
    ) {
      contractHeaderId
      contractNumber
      formalContractNumber
      vendorId
      contractYear
      renewalYear
      solicitationId
      solicitationPeriodId
      contractUpiid
      isActive
      vendor {
        id
        fleetVendorNumber
        vendorName
        uniqueEntityIdentifier
      }
      solicitation {
        id
        title
        purchaseTypeCode
        solicitationUpiid
        solicitationPeriods {
          id
          periodType
        }
      }
    }
  }
`;

export const GET_FUEL_AND_SIN_FOR_CONTRACT_REPORT = gql`
  query getFuelAndSINForContractReport(
    $purchaseTypes: [String!]!
    $contractYears: [Float!]!
  ) {
    getFuelAndSINForContractReport(
      purchaseTypes: $purchaseTypes
      contractYears: $contractYears
    )
  }
`;

export const GET_CONTRACT_LINES_FOR_REPORT = gql`
  query getContractLinesForReport(
    $contractNumbers: [String!]!
    $contractYear: Float
    $solicitationIds: [Float!]
    $vendorIds: [String!]
  ) {
    getContractLinesForReport(
      contractNumbers: $contractNumbers
      contractYear: $contractYear
      solicitationIds: $solicitationIds
      vendorIds: $vendorIds
    )
  }
`;
