import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import ReactHtmlParser from 'react-html-parser';
import { AccordionItem, Button, AFPTable } from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { AccordionTitle } from '../components/tags';
import {
  getHandelToggle,
  getToggleAccordion,
} from '../components/accordion-helpers';
import { getMinReqColumns, EcExpandedRow } from '../provider/table-defs-ec';
import {
  INPUT_TYPE_CODE,
  INPUT_DROPDOWN_OTHER_OPTION,
  CRITERIA_CODE,
  isMinReqItemReady,
} from '../provider/helpers';
import { AUTO_SAVE_BID_LINE_EC } from '../provider/queries';
import '../bid-line-details.scss';
import { TAB_KEY } from '../copy-line-item/helpers';

const MinimumRequirements = ({ openCopyModal }) => {
  const {
    bidLine,
    standardECs,
    standardECTable: items,
    setStandardECTable: setItems,
    setStandardEC,
    hasSomeHighlightByRowIds,
    getStandardEC,
    isAdmin,
  } = useBidLineDetails();

  const toggleAccordion = getToggleAccordion(setItems);
  const handleToggle = getHandelToggle(setItems);

  const [autoSaveBidLineEC] = useMutation(AUTO_SAVE_BID_LINE_EC);

  const autoSave = async (original, updates) => {
    const input = {};
    if (original.bidLineEcId) {
      // existing line
      input.id = original.bidLineEcId;
    } else {
      // new line
      input.bidLineId = +bidLine.id;
      input.associationId = original.id;
      input.associationTypeCode = original.associationTypeCode;
      input.equipmentCode = original.equipmentCode;
      input.inputType = original.inputTypeCode;
    }
    if (updates.inputValue !== undefined) input.value = updates.inputValue;
    if (updates.inputException !== undefined)
      input.exception = updates.inputException;
    if (updates.inputExplanation !== undefined)
      input.explanation = updates.inputExplanation;
    if (updates.comply !== undefined) {
      if (updates.comply === 'N') input.comply = false;
      else {
        input.exception = null; // remove expection
        input.comply = updates.comply === 'Y' ? true : null;
      }
    }
    // for admin user, return the original bid-line-ec id
    // this prevents the auto-save but still allows the UI to update
    if (isAdmin) {
      return { bidLineEcId: original.bidLineEcId };
    }
    return autoSaveBidLineEC({ variables: { input } })
      .then(async ({ data }) => ({ bidLineEcId: data.autoSaveBidLineEC }))
      .catch((error) => ({ error }));
  };

  const updateItem = async (original, updates) => {
    // update UI states
    const data = { ...updates };
    const newLine = { ...original, ...updates };
    data.ready = isMinReqItemReady(
      newLine.inputValue,
      newLine.comply,
      newLine.inputException,
    );
    if (updates.comply === 'Y') data.inputException = '';
    const table = newLine.comply === 'N' ? { isExpanded: true } : undefined;
    setStandardEC(original, { data, table });

    // auto save updates
    const { bidLineEcId, error } = await autoSave(original, updates);

    // roll back UI states if autosave fails
    if (error) {
      setStandardEC(original, { data: original });
      // global gql API error Alert automatically shown
      return;
    }

    // update bidLineEcId if new line is successfully added
    if (bidLineEcId !== original.bidLineEcId)
      setStandardEC(original, { data: { bidLineEcId } });
  };

  const onUpdate = (original, type, value) => {
    const { criteriaCode, lowerBound, upperBound } = original;
    let comply;
    switch (type) {
      case 'comply':
        if (original.comply !== value) updateItem(original, { comply: value });
        break;
      case INPUT_TYPE_CODE.DROPDOWN:
        if (original.inputValue !== value) {
          if (value === INPUT_DROPDOWN_OTHER_OPTION.value) comply = 'N';
          else comply = value ? 'Y' : '';
          updateItem(original, { inputValue: value, comply });
        }
        break;
      case INPUT_TYPE_CODE.COMPLY:
        if (original.inputValue !== value) {
          updateItem(original, { inputValue: value, comply: value });
        }
        break;
      case INPUT_TYPE_CODE.NUMERICAL:
        if (original.inputValue !== value) {
          if (value)
            comply =
              lowerBound == null ||
              (criteriaCode === CRITERIA_CODE.MIN && value >= lowerBound) ||
              (criteriaCode === CRITERIA_CODE.MAX && value <= lowerBound) ||
              value - lowerBound === 0
                ? 'Y'
                : 'N';
          else comply = '';
          updateItem(original, { inputValue: value, comply });
        }
        break;
      case INPUT_TYPE_CODE.RANGE:
        if (original.inputValue !== value) {
          if (value)
            comply =
              (lowerBound == null || value >= lowerBound) &&
              (upperBound == null || value <= upperBound)
                ? 'Y'
                : 'N';
          else comply = '';
          updateItem(original, { inputValue: value, comply });
        }
        break;
      case INPUT_TYPE_CODE.TEXT:
        if (original.inputValue !== value)
          updateItem(original, { inputValue: value });
        break;
      default:
        // case 'inputException' and 'inputExplanation':
        if (original[type] !== value) updateItem(original, { [type]: value });
    }
  };

  const columns = useMemo(() => getMinReqColumns(onUpdate), [onUpdate]);
  const subrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => (
      <EcExpandedRow ecType="S" row={row} onUpdate={onUpdate} />
    ),
    [onUpdate],
  );

  return (
    <div className="margin-bottom-8 bid-line-item-details">
      <h3>Minimum requirements</h3>
      <div className="display-flex flex-row flex-justify flex-align-end margin-bottom-4">
        <div style={{ maxWidth: 760 }}>
          {/* Using ReactHtmlParser as it is safer than dangerouslySetInnerHTML */}
          {bidLine.standardItem.content?.content
            ? ReactHtmlParser(bidLine.standardItem.content?.content)
            : ''}
        </div>
        <div>
          <Button
            variant="unstyled"
            label="Open/close all"
            leftIcon={{
              name: 'unfold_more',
            }}
            onClick={toggleAccordion}
          />
          <Button
            variant="outline"
            label="Copy minimum requirements"
            leftIcon={{
              name: 'content_copy',
            }}
            className="margin-left-2"
            onClick={() => openCopyModal(TAB_KEY.minimumRequirements)}
          />
        </div>
      </div>

      {items.map((item, i) => {
        const ecs = standardECs[item.index].data;
        const bidLineEcIds = ecs.map((ec) => ec.bidLineEcId);
        const tableRows = item.data;
        const clarificationIds = tableRows.flatMap((row) => {
          const d = getStandardEC(row);
          const ec = bidLine.equipmentCodes?.find(
            (e) => e.id === d.bidLineEcId,
          );
          const ids =
            ec?.clarifications
              ?.filter((c) => c.clarificationType === 'C')
              .map((c) => c.id) || [];
          return ids;
        });
        const hasSomeClirificationHighlights = hasSomeHighlightByRowIds(
          'min',
          'bid_line_ec_clarification',
          clarificationIds,
        );
        const hasSomeMinReqEcHighlights = hasSomeHighlightByRowIds(
          'min',
          'bid_line_ec',
          bidLineEcIds,
        );
        const isAccordionHighlighted =
          hasSomeClirificationHighlights || hasSomeMinReqEcHighlights;
        return (
          <AccordionItem
            key={item.id}
            id={item.id}
            title={
              <AccordionTitle
                title={item.title}
                // eslint-disable-next-line
                isReady={ecs.every((row) => row.ready)}
                isHighlighted={isAccordionHighlighted}
              />
            }
            expanded={item.expanded}
            content={
              item.expanded && (
                <div style={{ paddingTop: 8 }}>
                  <AFPTable
                    fullWidth
                    columns={columns}
                    data={item.data}
                    renderRowSubComponent={subrow}
                  />
                </div>
              )
            }
            handleToggle={() => handleToggle(i)}
          />
        );
      })}
    </div>
  );
};

MinimumRequirements.propTypes = {
  openCopyModal: PropTypes.func.isRequired,
};

export default MinimumRequirements;
